// Use this to write your custom SCSS

html {
    scroll-behavior: smooth;
}

body {
    font-weight: $font-weight-light;
    font-family: $font-family-sans-serif !important;
}

// we hate rounded corners
.btn,
.btn-close:before,
.swiper-controls .swiper-navigation .swiper-button,
.swiper-controls .swiper-pagination .swiper-pagination-bullet,
.hs-button,
.card,
.card-img-top,
.nav-pills .nav-link,
.badge,
.form-control,
.modal-content,
.dropdown-menu,
.tooltip-inner,
mark,
.mark,
.form-select,
.owl-carousel .owl-dots .owl-dot span,
.plyr,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.filter:not(.basic-filter) ul li + li:before,
.post-meta li:before,
.gloader, .page-loader:before, .swiper-hero:before 
{
    border-radius: 0 !important;
}

.form-check-input[type="radio"],
.progress-wrap {
    border-radius: 50% !important;
}

//----------------------------------------------------------//
//	FONT ICONS
//----------------------------------------------------------//
@font-face {
    font-family: "Custom";
    src: url("/static/theme/dist/assets/fonts/custom/Custom.eot");
    src:
        url("/static/theme/dist/assets/fonts/custom/Custom.eot#iefix")
            format("embedded-opentype"),
        url("/static/theme/dist/assets/fonts/custom/Custom.woff") format("woff"),
        url("/static/theme/dist/assets/fonts/custom/Custom.svg#Custom")
            format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.progress-wrap:after {
    font-family: "remixicon";
    content: "\ea78";
}

.btn-close:before {
    font-family: "remixicon";
    content: "\eb99";
}

.swiper-controls .swiper-navigation .swiper-button.swiper-button-next:after,
.uil-arrow-right:before {
    font-family: "remixicon";
    content: "\ea6e";
    font-style: normal;
}
.swiper-controls .swiper-navigation .swiper-button.swiper-button-prev:after,
.uil-arrow-left:before {
    font-family: "remixicon";
    content: "\ea64";
    font-style: normal;
}

[class^="ri-"],
[class*=" ri-"] {
    display: inline-block;
    transform: translateY(0.1rem);
    -webkit-transform: translateY(0.1rem);
}

[data-conditionally-hidden="true"] {
    visibility: hidden;
}

@each $color,
$value in $colors {
  .text-outline-#{$color} {
    text-shadow: 1px 1px 1px var(--#{$prefix}#{$color}), 1px -1px 1px var(--#{$prefix}#{$color}), -1px 1px 1px var(--#{$prefix}#{$color}), -1px -1px 1px var(--#{$prefix}#{$color});
  }
}

