html {
  // scroll-snap-type: y mandatory;
  // scroll-padding-top: 200px;
  // scroll-padding-bottom: 200px;
}

body {
  cursor: default;
  overflow-y: scroll;
}

.fs-xs {
  font-size: .55rem!important;
}

:focus {
  outline: 0
}

header .logo img {
  width: 150px;
  height: 150px;
  position: fixed;
  left: 75px;
  box-shadow: 0px 0px 2px 1px rgba(255, 255, 255, 1);
}

@media (max-width:1023px) {
  header .logo img {
    width: 80px;
    height: 80px;
    left: 40px;
  }
}

.alert {
  border-radius: 0 !important;
}

.wrapper {
}

.fixed {
  position: fixed !important
}

.navbar.page-navbar nav {
  background-color: rgba(255, 255, 255, 0);
  margin-top: 3px
}

.navbar.page-navbar nav:hover {
  -webkit-transition: all .4s ease-out;
  transition: all .4s ease-out
}

.navbar.page-navbar .nav-link {
  padding: .4rem 3rem .6rem 15px;
  margin-bottom: 2px;
  font-size: small;
  color: rgba(63, 63, 63, .8);
  background-color: rgba(255, 255, 255, .5);
  -webkit-transform: translateX(208px);
  transform: translateX(208px);
  -webkit-transition: all .4s ease-out;
  transition: all .4s ease-out;
  width: 250px;
  max-width: 250px
}

.navbar.page-navbar .nav-link:hover {
  background-color: rgba(255, 255, 255, .85);
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

.navbar.page-navbar .nav-link.active {
  -webkit-transition: all .4s ease-out;
  transition: all .4s ease-out;
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

.language-select .dropdown-menu {
  left: unset !important;
  min-width: unset !important;
}

//----------------------------------------------------------//
//	SMARTMENUS
//----------------------------------------------------------//
.navbar-nav .sub-arrow {
  font-family: $font-family-custom-icons;
}

.dropdown-mega .dropdown-menu {
  left: 220px !important;
}

.navbar-nav.sm-collapsible .sub-arrow {
  top: 0;
  margin: -0.75rem 0 0 0;
  border: 0;
  border-radius: 0;
  padding: 0 2rem 0 0;
  @include font-size(0.5rem);
  line-height: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

@media (min-width: 992px) {
  .navbar-nav.sm-collapsible .sub-arrow {
    top: 0;
    margin: -0.1rem 0 0 0;
    border: 0;
    border-radius: 0;
    padding: 0 2rem 0 0.5rem;
    @include font-size(0.25rem);
    line-height: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .navbar-expand-lg.transparent.fixed .navbar-nav .dropdown:not(.dropdown-submenu):not(.language-select)>.dropdown-menu {
    margin-top: unset !important;
  }
}

.offcanvas.offcanvas-start {
  width: unset !important;
}

.navbar-nav.sm-collapsible .sub-arrow::before,
.navbar-nav.sm-collapsible .show>a>.sub-arrow::before {
  content: "\e900";
  display: table-cell;
  vertical-align: middle;
}

.navbar-nav.sm-collapsible .dropdown-menu .dropdown-menu {
  margin: 0 0 0 1rem;
}

.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  display: inline;
  margin-left: 0.3rem;
  vertical-align: 0.1rem;
  border: 0 !important;
  @include font-size(0.25rem);
  color: $main-color;
}

.navbar.caret-none .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  display: none;
}

.navbar-dark .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  color: $navbar-dark-color;
}

.navbar-dark.transparent.banner--stick .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  color: $main-color;
}

.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow+.sub-arrow {
  display: none !important;
}

.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow:before {
  position: relative;
  content: "\e900";
}

.navbar-nav:not(.sm-collapsible) .dropdown-item .sub-arrow,
.navbar-nav:not(.sm-collapsible):not([data-sm-skip]) .dropdown-menu .dropdown-toggle::after {
  @include font-size(0.35rem);
  top: 0.55rem;
  right: 1.5rem;
  margin: 0;
  border: 0;
}

.navbar-nav:not(.sm-collapsible) .dropdown-item .sub-arrow:before,
.navbar-nav:not(.sm-collapsible):not([data-sm-skip]) .dropdown-menu .dropdown-toggle::after {
  position: relative;
  content: "\e902";
}

.navbar-nav:not(.sm-collapsible) .dropdown-item.has-submenu {
  padding-right: 2.5rem;
}

//----------------------------------------------------------//
//	DROPDOWN TOGGLE
//----------------------------------------------------------//
.dropdown-menu {
  box-shadow: 0 0 2rem rgba(30, 34, 40, .2);
}

.dropdown-toggle::after {
  font-family: $font-family-custom-icons;
  border: 0;
  margin-left: 0.2rem;
  content: "\e900";
  color: $main-color;
}

@media (max-width: 991.98px) {
  .dropdown-toggle::after {
    vertical-align: 0.2rem;
  }
}

.navbar-dark:not(.fixed) .dropdown-toggle:after,
.navbar-bg-dark .dropdown-toggle:after,
.btn.dropdown-toggle:after {
  color: $white;
}

@media (min-width: 992px) {
  .banner--stick .navbar-nav>li>ul.mega-menu .mega-menu-content {
    padding: 2rem 0.25rem 0.2rem;
  }
}

.vh-100 {
  height: 100vh !important
}

.vh-90 {
  height: 90vh !important
}

.vh-80 {
  height: 80vh !important
}

.vh-70 {
  height: 70vh !important
}

.vh-60 {
  height: 60vh !important
}

.vh-50 {
  height: 50vh !important
}

@media screen and (min-width: 576px) {
  .vh-sm-100 {
    height: 100vh !important
  }

  .vh-sm-90 {
    height: 90vh !important
  }

  .vh-sm-80 {
    height: 80vh !important
  }

  .vh-sm-70 {
    height: 70vh !important
  }

  .vh-sm-60 {
    height: 60vh !important
  }

  .vh-sm-50 {
    height: 50vh !important
  }
}

@media screen and (min-width: 768px) {
  .vh-md-100 {
    height: 100vh !important
  }

  .vh-md-90 {
    height: 90vh !important
  }

  .vh-md-80 {
    height: 80vh !important
  }

  .vh-md-70 {
    height: 70vh !important
  }

  .vh-md-60 {
    height: 60vh !important
  }

  .vh-md-50 {
    height: 50vh !important
  }
}

@media screen and (min-width: 992px) {
  .vh-lg-100 {
    height: 100vh !important
  }

  .vh-lg-90 {
    height: 90vh !important
  }

  .vh-lg-80 {
    height: 80vh !important
  }

  .vh-lg-70 {
    height: 70vh !important
  }

  .vh-lg-60 {
    height: 60vh !important
  }

  .vh-lg-50 {
    height: 50vh !important
  }
}

@media screen and (min-width: 1200px) {
  .vh-xl-100 {
    height: 100vh !important
  }

  .vh-xl-90 {
    height: 90vh !important
  }

  .vh-xl-80 {
    height: 80vh !important
  }

  .vh-xl-70 {
    height: 70vh !important
  }

  .vh-xl-60 {
    height: 60vh !important
  }

  .vh-xl-50 {
    height: 50vh !important
  }
}

@media screen and (min-width: 1400px) {
  .vh-xxl-100 {
    height: 100vh !important
  }

  .vh-xxl-90 {
    height: 90vh !important
  }

  .vh-xxl-80 {
    height: 80vh !important
  }

  .vh-xxl-70 {
    height: 70vh !important
  }

  .vh-xxl-60 {
    height: 60vh !important
  }

  .vh-xxl-50 {
    height: 50vh !important
  }
}


@media (min-width:768px) {

  .navbar,
  .navbar-sub {
    padding-left: 220px !important
  }
}

@media (min-width:991.98px) {
  .navbar-nav>li>ul.mega-menu {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important
  }
}

.topbar {
  padding-right: 24px
}

.topbar a {
  font-size: .7rem !important;
  font-weight: 400;
  line-height: 36px
}

.topbar i {
  font-size: .8rem !important;
  font-weight: 400;
  line-height: 36px
}

.z-top {
  z-index: 2000 !important
}

.z-1000 {
  z-index: 1000 !important;
}

.offcanvas-info {
  right: -20rem;
  width: 20rem
}

@media (max-width:991.98px) {
  .offcanvas-nav {
    left: -80vw;
    width: 80vw
  }

  .offcanvas-info {
    right: -80vw;
    width: 80vw
  }
}

.fw-light {
  font-weight: 300 !important
}

.fw-light strong {
  font-weight: 700 !important
}

.tooltip,
p.small {
  margin-bottom: 0;
  font-size: rfs-fluid-value(.7rem) !important
}

a.active {
  color: #009be1 !important
}

ul {
  list-style-type: square;
  padding-left: 1rem !important
}

ul.list-unstyled,
ul.no-list-type {
  list-style-type: none;
  padding-left: 0 !important
}

.list-group-horizontal>.list-group-item+.list-group-item:nth-child(even) {
  margin-top: -50px;
}

.card-body.fixed {
  height: 17rem;
  overflow: hidden
}

.card-img-top img {
  border-radius: 0 !important
}

.card-img-top.fixed {
  height: 15rem !important
}

.card-border-start:after,
.card-border-end:after,
.card-border-top:after,
.card-border-bottom:after {
  border-radius: 0 !important;
}

.post-meta li.no-dot:before {
  background: 0 0
}

.icon-box .icon i.uil {
  font-size: rfs-fluid-value(6rem);
  line-height: 6rem
}

.as-1-1 {
  aspect-ratio: 1/1;
  height: unset !important
}

.as-16-9 {
  aspect-ratio: 16/9;
  height: unset !important
}

.as-2-1 {
  aspect-ratio: 2/1;
  height: unset !important
}

.as-3-1 {
  aspect-ratio: 3/1;
  height: unset !important
}

.as-3-2 {
  aspect-ratio: 3/2;
  height: unset !important
}

.as-4-1 {
  aspect-ratio: 4/1;
  height: unset !important
}

a.disabled {
  pointer-events: none
}

.shape-top {
  top: -1.5rem
}

.shape-bottom {
  bottom: -2.5rem
}

.shape-left {
  left: -2.5rem
}

.shape-right {
  right: -2.5rem
}

.z-n {
  z-index: -1000 !important
}

.bg-black {
  background-color: #000 !important
}

.rotate-90 {
  -webkit-transform: translateY(.1rem) rotate(90deg) !important;
  transform: translateY(.1rem) rotate(90deg) !important
}

.tilt-right {
  -webkit-transform: translateY(.1rem) rotate(10deg) !important;
  transform: translateY(.1rem) rotate(10deg) !important
}

.tilt-left {
  -webkit-transform: translateY(.1rem) rotate(-10deg) !important;
  transform: translateY(.1rem) rotate(-10deg) !important
}

.badge {
  position: relative;
  padding: 5px 7.5px !important;
  font-size: 10px !important;
  line-height: 10px !important;
  height: 20px !important;
  vertical-align: middle !important;
  text-align: center !important;

  &.trailing {
    margin-left: 1rem !important;
  }

  &.leading {
    margin-right: 1rem !important;
  }
}

@each $color,
$value in map-merge(("primary": $main-color,
  ),
  $colors) {
  .badge.trailing.bg-#{$color}::before {
    border-color: transparent $value transparent transparent !important;
  }
}

@each $color,
$value in map-merge(("primary": $main-color,
  ),
  $colors) {
  .badge.leading.bg-#{$color}::after {
    border-color: transparent transparent transparent $value  !important;
  }
}

.badge.trailing::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  border-style: solid;
  border-width: 10px 10px 10px 0;
}

.badge.leading::after {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  border-style: solid;
  border-width: 10px 0 10px 10px;
}

.trigger video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.trigger::after {
  font-family: 'remixicon';
  content: '\ea4e';
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
}

.scroll-sticky-top {
  position: sticky;
  position: -webkit-sticky;
  top: 10%;
  z-index: -1;
}

.scroll-sticky-bottom {
  position: sticky;
  position: -webkit-sticky;
  top: 80%;
  z-index: -1;
}

.absolute {
  position: absolute !important;
}

.absolute.top {
  top: 20%;
}

.absolute.middle {
  top: 50%;
}

.absolute.bottom {
  bottom: 10%;
}

.absolute.start {
  left: 10%;
}

.absolute.center {
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute.end {
  right: 10%;
}

//Scroll Sequence

.canvas-wrapper {}

canvas {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  max-height: 100vh;
  z-index: -10;
}

.scroll-content {
  padding: 4rem;
  z-index: -1;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

figure.thumb-sm {
  max-height: 150px;
  max-width: 150px;
}

figure.thumb-md {
  max-height: 300px;
  max-width: 300px;
}

figure.thumb-lg {
  max-height: 450px;
  max-width: 450px;
}

.card-footer,
.card-header {
  border: none !important;
}

@media (min-width: 992px) {

  .navbar.extended .navbar-collapse-wrapper,
  .navbar.fancy .navbar-collapse-wrapper {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.btn {
  text-transform: uppercase;
}

.btn>.disabled {
  color: inherit !important;
  background-color: inherit !important;
  border: inherit !important;
}


.bg-white-50 {
  background-color: rgba(white, 0.5);
}

.bg-overlay-light:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(white, 0.5);
}

.bg-underlay-light:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(white, 0.5);
}

.flex-header .card-header {
  flex: 1 1 auto !important;
}

.flex-header .card-body {
  flex: none;
}

//----------------------------------------------------------//
//	IMAGE BACKGROUND WRAPPER
//----------------------------------------------------------//
.image-wrapper {
  background: no-repeat center center;
  background-size: cover;
  position: relative;
  z-index: 0;

  &:not(.mobile) {
    background-attachment: fixed !important;
  }

  &.bg-auto {
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll !important;
  }

  &.bg-full {
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll !important;
  }

  &.bg-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll !important;
  }

  &.bg-overlay:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba($gray-900, 0.5);
  }

  &.bg-overlay-light:before {
    background: rgba($white, 0.5);
  }

  &.bg-overlay-gradient-light:before {
    background: linear-gradient(rgba($white, 0.99), rgba($white, 0.5), rgba($white, 0), rgba($white, 0))
  }

  &.bg-overlay-200:before {
    background: rgba($gray-900, 0.2);
  }

  &.bg-overlay-300:before {
    background: rgba($gray-900, 0.3);
  }

  &.bg-overlay-400:before {
    background: rgba($gray-900, 0.4);
  }

  &.bg-overlay-600:before {
    background: rgba($gray-900, 0.6);
  }

  &.bg-overlay-700:before {
    background: rgba($gray-900, 0.7);
  }

  &.bg-overlay-800:before {
    background: rgba($gray-900, 0.8);
  }

  &.bg-overlay-900:before {
    background: rgba($gray-900, 0.9);
  }

  &.bg-overlay:not(.bg-content) *,
  &.bg-overlay.bg-content .content {
    position: relative;
    z-index: 2;
  }

  &.card:before {
    border-radius: $border-radius  !important;
  }
}

.wrapper-fade {
  position: relative;
  z-index: 0;

  &.fg-overlay:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.5);
  }

  &.fg-overlay-fadeout:after {
    background: linear-gradient(rgba($white, 0), rgba($white, 0), rgba($white, 0.7), $white);
  }
}

.image-wrapper.card:before {
  border-radius: 0 !important;
}

//----------------------------------------------------------//
//	ADDITIONAL MARGINS
//----------------------------------------------------------//
[class*="mt-n"],
[class*="mb-n"] {
  position: relative;
  z-index: 3;
}

.mt-lg-n16 {
  margin-top: -4.25rem !important;
}

.mb-n50p {
  transform: translateY(50%);
}

.mt-n50p {
  transform: translateY(-50%);
}

@media screen and (min-width: 576px) {
  .mb-sm-n50p {
    transform: translateY(50%);
  }

  .mt-sm-n50p {
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 768px) {
  .mb-md-n50p {
    transform: translateY(50%);
  }

  .mt-md-n50p {
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 992px) {
  .mb-lg-n50p {
    transform: translateY(50%);
  }

  .mt-lg-n50p {
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 1200px) {
  .mb-xl-n50p {
    transform: translateY(50%);
  }

  .mt-xl-n50p {
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 1400px) {
  .mb-xxl-n50p {
    transform: translateY(50%);
  }

  .mt-xxl-n50p {
    transform: translateY(-50%);
  }
}

.mw-50 {
  max-width: 50%;
}

.mh-40 {
  max-height: 40%;
  overflow-y: hidden;
}

.mh-50 {
  max-height: 50%;
  overflow-y: hidden;
}


// Fluid Font Sizes

h1,
.h1 {
  font-size: rfs-fluid-value(3rem);
}

h2,
.h2 {
  font-size: rfs-fluid-value(2.5rem);
}

h3,
.h3 {
  font-size: rfs-fluid-value(1.75rem);
}

h4,
.h4 {
  font-size: rfs-fluid-value(1.5rem);
}

h5,
.h5 {
  font-size: rfs-fluid-value(1.25rem);
}

h6,
.h6 {
  font-size: rfs-fluid-value(1.1rem);
}

.btn {
  font-size: rfs-fluid-value(0.8rem);
  white-space: unset;
}

// p {
//   font-size: rfs-fluid-value(1rem);
// }

// .lead, .fs-lg {
//   font-size: rfs-fluid-value(1.1rem);
// }

// .fs-sm {
//   font-size: rfs-fluid-value(0.9rem);
// }

// Hubspot Forms

.hbspt-form fieldset {
  margin-bottom: 0.5rem !important;
}

.hbspt-form ul.hs-error-msgs,
.hbspt-form .hs-fieldtype-checkbox ul,
.hbspt-form .hs-fieldtype-radio ul {
  list-style-type: none;
  padding-left: 0 !important;
}

.hbspt-form .hs-fieldtype-checkbox ul span,
.hbspt-form .hs-fieldtype-radio ul span {
  font-weight: normal;
}

.hbspt-form label.hs-form-checkbox-display,
.hbspt-form label.hs-form-radio-display {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.hbspt-form input[type="checkbox"],
.hbspt-form input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1em !important;
  height: 1em;
  border: 0.1em solid currentColor;
  transform: translateY(0.25em);
  display: grid;
  place-content: center;
}

.hbspt-form input[type="radio"] {
  border-radius: 50% !important;
}

.hbspt-form input[type="checkbox"]::before,
.hbspt-form input[type="radio"]::before {
  content: "";
  width: 0.5em;
  height: 0.5em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em $primary;
}

.hbspt-form input[type="radio"]::before {
  border-radius: 50% !important;
}

.hbspt-form input[type="checkbox"]:checked::before,
.hbspt-form input[type="radio"]:checked::before {
  transform: scale(1);
}

.hbspt-form label span {
  font-weight: bold;
}

.hbspt-form .hs-form-required,
.hbspt-form .hs-error-msg {
  font-weight: normal;
  color: darkred;
}

.hbspt-form .hs-input {
  border: none;
  border-bottom: 1px solid darkgray;
}

.hbspt-form .legal-consent-container {
  font-size: 0.66rem;
}

.hbspt-form input[type="submit"].hs-button {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  letter-spacing: $letter-spacing;
  color: $body-color;
  text-align: center;
  text-decoration: if($link-decoration==none, null, none);
  text-transform: uppercase;
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  box-sizing: border-box;
  transform: translateY(0);
  @include button-size($btn-padding-y,
    $btn-padding-x,
    $btn-font-size,
    $btn-border-radius);
  @include transition($btn-transition);

  &:hover {
    transform: translateY(-0.15rem);
    @include box-shadow($btn-active-box-shadow);
    color: $white;
    text-decoration: if($link-hover-decoration==underline, none, null);
  }

  &.large {
    width: 100%;
    height: 3rem;
  }

  &.primary {
    color: white;
    background-color: $primary;
  }
}

blockquote {
  &.icon-event {
    position: relative;
    border: 0;
    padding: 0;

    &:before {
      font-family: 'remixicon';
      content: "\eb21";
      position: absolute;
      left: -0.9rem;
      color: rgba($navy, 0.05);
      font-size: 5rem;
      line-height: 1;
      z-index: 1;
    }
  }
}

.glightbox-clean .gclose:after {
  font-family: remixicon !important;
  content: "\eb99";
}

.glightbox-clean .gnext:before {
  font-family: remixicon !important;
  content: "\ea6e";
}

.glightbox-clean .gprev:before {
  font-family: remixicon !important;
  content: "\ea64";
}

.more::after {
  font-family: remixicon;
  content: "\ea6e";
  vertical-align: -0.1rem;
  // font-size: unset;
}

.external::after {
  font-family: remixicon !important;
  content: "\ecaf";
  font-size: unset;
}

.internal::after {
  font-family: remixicon;
  content: "\eeaf";
  font-size: unset;
}

.accordion-wrapper .card-header button:before {
  font-family: remixicon !important;
  content: "\ea77";
}

.accordion-wrapper .card-header button.collapsed:before {
  content: "\ea4d";
}

.l-header .search-form {
  margin-left: 200px;
}

.search-form:before {
  font-family: remixicon !important;
  content: "\f0cd";
}

input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

.swiper-button-disabled {
  opacity: 0 !important;
}

iframe .cookieconsent-optin-marketing::before {
  content: 'Cookie-Wahnsinn';
}

.element {
  background-color: white;
}

.card.hover {
  perspective: 1000px;
}

.card.hover .element {
  transform-origin: 100% 100%;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transition: all 350ms ease;
  transform: rotateX(90deg);
  // opacity: 0;
}

.card.hover:hover .element {
  transform: rotateX(0);
  // opacity: 1;
}

pre {
  padding: 0.2rem;
}

.text-line {
  padding-left: 1rem !important;
}
.text-line:before {
  top: unset;
  bottom: 0.1rem;
  width: .5rem;
  height: .5rem;
}

[class*=" ri-"], [class^=ri-] {
  transform: translateY(0) !important;
}

// Dynamics Forms

form {
   font-family: Helvetica Neue Light, Helvetica, Arial, sans-serif;
}

form a {
  font-weight: bold;
  color: #009be1 !important;
  text-decoration: none;
}

form a:hover {
  text-decoration: underline;
}

form .lp-form-fieldInput {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

form div.lp-form-field {
      word-wrap: break-word; word-break: break-word;
}

form div.lp-radioButtonsContainer {
    width: 50%;
}

form span.lp-radioButton input {
  appearance: auto !important;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  margin-top: 3px;
  border-radius: 50%;
}

form div.marketing-customfield input[type="checkbox"],
form div.marketing-field input[type="checkbox"],
form div.marketing-subscription-list input {
  appearance: auto !important;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-top: 3px;
  border-radius: 0px;
}

form span.lp-checkboxListItem,
form span.lp-radioButton {
/* This rule is needed to style all radio button fields. For product constraints each option is defined as input and label wrapped into a span*/
    display: block;
    margin: 2px;
}

form *[data-layout="true"] {
    margin: 0 auto;
    max-width: /* @layout-max-width */
    100%; /* @layout-max-width */
    ;
}

.onFormSubmittedFeedbackMessage {
  font-family: unset !important;
}

form div.lp-radioButtonsContainer {
  width: 100%;
}

form input {
  border-radius: 0px;
  border: 1px solid #333333;
  height: 35px;
  margin-top: 8px;
  text-indent: 10px;
  width: 100%;
}
form select {
  border-radius: 0px;
  border: 1px solid #333333;
  margin-top: 8px;
  text-indent: 10px;
  width: 100%;
}
form textarea {
  border-radius: 0px;
  border: 1px solid #333333;
  margin-bottom: 8px;
  margin-top: 8px;
  height: 120px;
  overflow: auto;
  width: 100%;
}
form label {
  margin: 0px;
  font-size:16px;
}

form .lp-radioButton label {
  position: relative;
  bottom: 3px;
}

form button[type="submit"],
form div[data-editorblocktype="SubmitButtonBlock"]  button {
  font-size:18px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  color: white;
  background-color: #009be1; 
  border: 1px solid #009be1;
  width: 100%;
  padding: 0;
}
form button[type="reset"],
form div[data-editorblocktype="ResetButtonBlock"]  button {
  font-size:18px;
  border-radius: 0px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  color: #323130; 
  border: 1px solid #8A8886;
  width: 100%;
  padding: 0;
}
form button[type="submit"],
form button[type="reset"],
form div[data-editorblocktype="SubmitButtonBlock"],
form div[data-editorblocktype="ResetButtonBlock"] 
{
  margin: 0 auto;
}
form .columnContainer h2
{
  font-size: 16px;
}
::-webkit-input-placeholder,
::-moz-placeholder,
::-ms-input-placeholder
::placeholder
{
  font-size: 16px;
}
form .columnContainer h1{
  font-size:26px;
}

@media only screen and (max-width: 768px) {
  form .columnContainer {
     width: 100% !important;
  }
  .navbar {
    padding-left: 120px !important;
  }
}

.text-gradient {
  display: inherit !important;
}

.sticky-top {
  top: 78px !important;
  z-index: 100 !important;
  padding-left: 260px;
}

@media only screen and (max-width: 1024px) {
  .sticky-top {
    padding-left: 150px !important;
  }
  .navbar {
    padding-left: 220px !important;
  }
}

.plyr__controls {
  display: none !important;
}