
// Use this to write your custom SCSS
[speech-bubble], [speech-bubble] * { box-sizing: border-box }

[speech-bubble]{
  --bbColor: grey;
  --bbArrowSize: 1.5rem;
  --bbBorderRadius: 0;
  --bbPadding: 1rem;
  background: var(--bbColor);
  border-radius: var(--bbBorderRadius);
  padding: var(--bbPadding);
  position: relative;
}

[speech-bubble]::before{
  content: '';
  position: absolute;
  background: var(--bbColor);
}

[speech-bubble][red]{background: $red}
[speech-bubble][sky]{background: $sky}
[speech-bubble][blue]{background: $blue}
[speech-bubble][grape]{background: $grape}
[speech-bubble][purple]{background: $purple}
[speech-bubble][violet]{background: $violet}
[speech-bubble][pink]{background: $pink}
[speech-bubble][fuchsia]{background: $fuchsia}
[speech-bubble][yellow]{background: $yellow}
[speech-bubble][green]{background: $green}
[speech-bubble][navy]{background: $navy}
[speech-bubble][ash]{background: $ash}
[speech-bubble][leaf]{background: $leaf}
[speech-bubble][aqua]{background: $aqua}
[speech-bubble][orange]{background: $orange}
[speech-bubble][red]::before{background: $red}
[speech-bubble][sky]::before{background: $sky}
[speech-bubble][blue]::before{background: $blue}
[speech-bubble][grape]::before{background: $grape}
[speech-bubble][purple]::before{background: $purple}
[speech-bubble][violet]::before{background: $violet}
[speech-bubble][pink]::before{background: $pink}
[speech-bubble][fuchsia]::before{background: $fuchsia}
[speech-bubble][yellow]::before{background: $yellow}
[speech-bubble][green]::before{background: $green}
[speech-bubble][navy]::before{background: $navy}
[speech-bubble][ash]::before{background: $ash}
[speech-bubble][leaf]::before{background: $leaf}
[speech-bubble][aqua]::before{background: $aqua}
[speech-bubble][orange]::before{background: $orange}

[speech-bubble][pbottom]{ margin-bottom: var(--bbArrowSize) }
[speech-bubble][ptop]   { margin-top: var(--bbArrowSize); }
[speech-bubble][pleft]  { margin-left: var(--bbArrowSize); }
[speech-bubble][pright] { margin-right: var(--bbArrowSize); }


/* bottom and top  */
[speech-bubble][pbottom]::before,
[speech-bubble][ptop]::before{
  --width: calc(var(--bbArrowSize) / 2 * 3);
  height: var(--bbArrowSize);
  width: var(--width);
}

/* bottom */
[speech-bubble][pbottom]::before{
  top: calc(100% - 2px);
}
[speech-bubble][pbottom][aleft]::before{
  left: 1rem;
  clip-path: polygon(25% 0, 100% 0, 0% 100%)
}
[speech-bubble][pbottom][acenter]::before{
  left: calc(50% - var(--width) / 2);
  clip-path: polygon(12.5% 0, 87.5% 0, 50% 100%)
}
[speech-bubble][pbottom][aright]::before{
  right: 1rem;
  clip-path: polygon(0 0, 75% 0, 100% 100%)
}

/* top */
[speech-bubble][ptop]::before{
  bottom: calc(100% - 2px);
}
[speech-bubble][ptop][aleft]::before{
  left: var(--bbPadding);
  clip-path: polygon(0 0, 100% 100%, 25% 100%)
}
[speech-bubble][ptop][acenter]::before{
  left: calc(50% - var(--width) / 2);
  clip-path: polygon(12.5% 100%, 50% 0, 87.5% 100%)
}
[speech-bubble][ptop][aright]::before{
  right: var(--bbPadding);
  clip-path: polygon(0 100%, 100% 0, 75% 100%)
}

/* left and right  */
[speech-bubble][pleft]::before,
[speech-bubble][pright]::before{
  --height: calc(var(--bbArrowSize) / 2 * 3);
  width: var(--bbArrowSize);
  height: var(--height);
}

/* right */
[speech-bubble][pright]::before{
  left: calc(100% - 2px);
}
[speech-bubble][pright][atop]::before{
  top: var(--bbPadding);
  clip-path: polygon(100% 0, 0 100%, 0 25%)
}
[speech-bubble][pright][acenter]::before{
  top: calc(50% - var(--height) / 2);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%)
}
[speech-bubble][pright][abottom]::before{
  bottom: var(--bbPadding);
  clip-path: polygon(0 0, 100% 100%, 0 75%)
}

/* left */
[speech-bubble][pleft]::before{
  right: calc(100% - 2px);
}
[speech-bubble][pleft][atop]::before{
  top: var(--bbPadding);
  clip-path: polygon(0 0, 100% 25%, 100% 100%)
}
[speech-bubble][pleft][acenter]::before{
  top: calc(50% - var(--height) / 2);
  clip-path: polygon(0 50%, 100% 12.5%, 100% 87.5%);
}
[speech-bubble][pleft][abottom]::before{
  bottom: var(--bbPadding);
  clip-path: polygon(0 100%, 100% 0, 100% 75%)
}

/* flip */
[speech-bubble][pbottom][flip]::before,
[speech-bubble][ptop][flip]::before{
  transform: scaleX(-1)
}
[speech-bubble][pleft][flip]::before,
[speech-bubble][pright][flip]::before{
  transform: scaleY(-1)
}

